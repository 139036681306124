import { useAuth } from 'oidc-react';

export default function LoggedIn() {
    const auth = useAuth();
    if (auth && auth.userData) {
        return (
            <div>
                <strong>Hello {auth.userData.profile.name}</strong><br />
                <button onClick={() => auth.signOut()}>Log out!</button>
            </div>
        );
    }
    return <div>Not logged in! Try to refresh to be redirected.</div>;
}
