import { AuthProvider } from "oidc-react";
import DashboardPage from "./pages/dashboard/DashboardPage";

const oidcConfig = {
    onSignIn: () => {
        // Redirect?
    },
    authority: 'https://auth.prohelika.net/realms/test',
    clientId: 'dashboard',
    redirectUri: window.location.origin,
};


export default function App() {
    return (
        <AuthProvider {...oidcConfig}>
            <DashboardPage />
        </AuthProvider>
    )
}
