import React from 'react'
import LoggedIn from '../../components/LoggedIn';

export default function DashboardPage() {
    return (

        <div className="App">
            <header className="App-header">
                <LoggedIn />
            </header>
        </div>

    );
}
